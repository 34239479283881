.linkButtonDisabled,
.linkButtonDisabled:hover {
	opacity: 0.5;
}

:global(.omni) .linkButtonDisabled,
:global(.omni) .linkButtonDisabled:hover {
	background-color: var(--theme-grey);
	border: none;
	box-shadow: inset 0 0 0 0.126rem var(--theme-grey-dark);
}
