.widgetGap {
	gap: 1rem 2rem;
}

.tealLine {
	width: 72px;
	height: 6px;
}

.widgetHeight {
	height: 310px;
}

.circle {
	height: 38px;
	width: 38px;
	border-radius: 50%;
	border: 2px solid #fff;
}

/*
*	display grid
*/
.grid {
	display: grid;
}

/*
*	grid 1 column
*/
.gridColumn1 {
	grid-template-columns: minmax(0, 1fr);
}

/*
*	grid 2 columns
*/
.gridColumn2 {
	grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
}

/*
*	grid 3 columns
*/
.gridColumn3 {
	grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
}

/*
*	custom grid for 5 items layout (3 first row, 2 second row)
*/
.gridCustom5 {
	grid-template-columns: repeat(12, 1fr);
}

.gridCustomItem:nth-child(-1n + 3) {
	grid-column: span 4;
}

.gridCustomItem:nth-last-child(2) {
	grid-row-start: 2;
	grid-column: 1 / span 6;
}

.gridCustomItem:nth-last-child(1) {
	grid-row-start: 2;
	grid-column: 7 / span 6;
}
