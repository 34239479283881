.icon {
	/* to align icon back with checkmark. Their sizes differ due to Tachyons sizing */
	position: relative;
	left: -0.25rem;
}

.circled {
	border-radius: 50%;
}

.homepageDashboardCircled {
	border-radius: 50%;
	width: 50px;
	height: 50px;
	border: 2px solid;
}

.line {
	height: 0.125rem;
}

.lineGradient {
	background: linear-gradient(to right, var(--theme-primary), var(--theme-grey-light) 90%);
}

.lastStep {
	/* The last "Delivered" status text is positioned differently */
	left: -2.75rem;
}

.processingStep {
	/* The "Processing" status text is positioned differently */
	left: -0.4rem;
}

.lastStepWidth {
	width: 3.125rem;
}
