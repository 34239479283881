.unitFontSize {
	font-size: 0.6em;
}

.proBadgeProSale {
	height: 22px;
	width: 91px;
}

.proBadgeSale {
	height: 22px;
	width: 57px;
}

.proPricingBadge {
	height: 22px;
	width: 102px;
}

.proSavingsBadge {
	height: 22px;
	width: 125px;
}

.popOverContent {
	margin: -10px -8px;
	width: 95vw;
}

@media (--breakpoint-not-small) {
	.popOverContent {
		margin: -10px -8px;
		width: 50vw;
	}
}
